import {apiRoutes} from 'api/apiRoutes';
import {sleep} from 'helper/sleep';
import cloneDeep from 'lodash/cloneDeep';
import responses from './responses';
import {http, passthrough, HttpResponse} from 'msw';
import config from 'settings/config';

const baseUrl =
  config.api === 'production' || config.api === 'development'
    ? 'https://api-:platform.lingvano.com/api/'
    : config.api;

export const database = {
  questions: [...responses.quizDynamic.response.questions] as Array<any>,
};

export const handlers = [
  http.get('/favicon.ico', () => passthrough()),
  http.post(baseUrl + apiRoutes.login, async ({request}) => {
    const body: any = await request.json();
    if (body.password === 'wrongPassword') {
      return HttpResponse.json(responses.login.fail, {status: 401});
    }
    return HttpResponse.json(responses.login.success, {status: 200});
  }),

  http.get(baseUrl + apiRoutes.searchContent, ({request, params}) => {
    if (!_isAuthorized(request)) {
      return HttpResponse.json(responses.errors.unauthorized, {status: 401});
    }

    let response = cloneDeep(responses.getSearchContent);
    if (params.platform !== 'asl') {
      response = JSON.parse(
        JSON.stringify(responses.getSearchContent).replace(
          /Module/g,
          `Module ${params.platform}`,
        ),
      );
    }

    return HttpResponse.json(response, {status: 200});
  }),

  http.get(`${baseUrl}${apiRoutes.quiz}:id`, ({request, params}) => {
    if (!_isAuthorized(request)) {
      console.log('WARNING IS HERE');
      return HttpResponse.json(responses.errors.unauthorized, {status: 401});
    }

    if (params.id === '404') {
      return HttpResponse.json(responses.errors.notFound, {status: 404});
    }

    if (
      params.id === 'dialog' ||
      params.id === '8095' ||
      params.id === '43744'
    ) {
      return HttpResponse.json(responses.quizDialog, {status: 200});
    }

    if (params.id === 'empty' || params.id === '8097') {
      return HttpResponse.json(responses.quizEmpty, {status: 200});
    }

    if (params.id === '8999') {
      return HttpResponse.json(responses.quizTpp, {status: 200});
    }

    if (params.id === 'dynamic') {
      const _updatedDynamicQuiz = _getUpdatedDynamicQuiz(database.questions);
      return HttpResponse.json(_updatedDynamicQuiz, {status: 200});
    }

    if (params.id === 'unknownFormat') {
      return HttpResponse.json(responses.quizUnknownFormat, {status: 200});
    }

    if (params.id === 'vocab_trainer_asl') {
      return HttpResponse.json(responses.quizTrainer, {status: 200});
    }

    return HttpResponse.json(responses.quizDiscover, {status: 200});
  }),

  http.get(`${baseUrl}${apiRoutes.teleprompter}`, ({request}) => {
    const url = new URL(request.url);
    if (!_isAuthorized(request)) {
      return HttpResponse.json(responses.errors.unauthorized, {status: 401});
    }

    if (url.searchParams.get('chapterIds') === 'unknown') {
      return HttpResponse.json(responses.errors.notFound, {status: 404});
    }

    if (url.searchParams.get('mode') === 'dialog') {
      return HttpResponse.json(responses.teleprompterDialog, {status: 200});
    } else if (url.searchParams.get('mode') === 'person-b') {
      return HttpResponse.json(responses.teleprompterPersonB, {status: 200});
    } else {
      return HttpResponse.json(responses.teleprompter, {status: 200});
    }
  }),

  http.post(
    `${baseUrl}${apiRoutes.quiz}:id/${apiRoutes.questions}`,
    async ({request}) => {
      if (!_isAuthorized(request)) {
        return HttpResponse.json(responses.errors.unauthorized, {status: 401});
      }

      const uniqueCounts: Array<string> = [];

      const body: any = await request.json();
      body.questions.forEach((question) => {
        if (uniqueCounts.includes(question.count)) {
          throw new Error('Duplicate count');
        }
        uniqueCounts.push(question.count);
      });

      return HttpResponse.json(responses.questionEditSuccess, {status: 200});
    },
  ),

  http.post(
    `${baseUrl}${apiRoutes.quiz}:id/${apiRoutes.autofillVideos}`,
    ({request}) => {
      if (!_isAuthorized(request)) {
        return HttpResponse.json(responses.errors.unauthorized, {status: 401});
      }

      database.questions[1] = {
        ...responses.quizDynamic.response.questions[1],
        question: '[qvideo id="1234" solution="HELLO"]',
      };

      return HttpResponse.json(
        {success: true, editedQuestions: [1234]},
        {status: 200},
      );
    },
  ),

  http.post(
    baseUrl + apiRoutes.quiz + ':id/' + apiRoutes.questions + apiRoutes.order,
    ({request}) => {
      if (!_isAuthorized(request)) {
        return HttpResponse.json(responses.errors.unauthorized, {status: 401});
      }
      return HttpResponse.json(responses.questionsOrderSuccess, {status: 200});
    },
  ),

  http.delete(
    baseUrl + apiRoutes.quiz + ':id/' + apiRoutes.questions + ':questionId',
    ({request}) => {
      if (!_isAuthorized(request)) {
        return HttpResponse.json(responses.errors.unauthorized, {status: 401});
      }
      return HttpResponse.json(responses.deleteQuestionSuccess, {status: 200});
    },
  ),

  http.get(`${baseUrl}${apiRoutes.lesson}:id`, ({request}) => {
    if (!_isAuthorized(request)) {
      return HttpResponse.json(responses.errors.unauthorized, {status: 401});
    }

    return HttpResponse.json(responses.lesson, {status: 200});
  }),

  http.post(`${baseUrl}${apiRoutes.asana}/tasks`, async ({request}) => {
    if (!_isAuthorized(request)) {
      console.log('WARNING IS HERE ASANA');
      return HttpResponse.json(responses.errors.unauthorized, {status: 401});
    }

    const body: any = await request.json();
    let response = HttpResponse.json(responses.asanaTaskCreated, {status: 200});
    if (body.title.toLowerCase().includes('fail')) {
      response = HttpResponse.json(responses.asanaTaskFailed, {status: 400});
    }

    // simulate delay in the Asana API to see loader
    if (body.title.toLowerCase().includes('delay')) {
      await sleep(3000);
    }

    return response;
  }),

  http.get(
    `${baseUrl}${apiRoutes.asana}/tasks/:quizId`,
    ({request, params}) => {
      if (!_isAuthorized(request)) {
        return HttpResponse.json(responses.errors.unauthorized, {status: 401});
      }

      const response = cloneDeep(responses.asanaTasks);
      response.response = response.response.filter(
        (t) => t.quizId === params.quizId,
      );
      return HttpResponse.json(response, {status: 200});
    },
  ),
];

const _getUpdatedDynamicQuiz = (updatedQuestions) => {
  return {
    success: true,
    response: {
      id: responses.quizDynamic.response.id,
      title: responses.quizDynamic.response.title,
      url: responses.quizDynamic.response.url,
      category: responses.quizDynamic.response.category,
      questions: updatedQuestions,
    },
  };
};

const _getToken = (req) => {
  return (req.headers.get('Authorization') ?? '').replace(/^Bearer\s+/, '');
};

const _isAuthorized = (req) => {
  if (_getToken(req) === 'invalid') {
    return false;
  }
  return _getToken(req) !== '';
};
